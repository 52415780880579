import logo from './logo.svg';
import './App.css';
import Navbar from './Navbar';
import './tailwind.css';
import FeatureCard from './FeatureCard';
import FAQ from './FAQ';
import check from './assets/check.png'; 
import nope from './assets/nope.png';
import aiEditingDemo from './assets/aiEditingDemo.gif';
import projectManagementTools from './assets/projectManagementTools.gif';
import trainingDataDemo from './assets/trainingDataDemo.gif';
import rfpScoringDemo from './assets/rfpScoringDemo.gif';
import grantDatabaseDemo from './assets/grantDatabaseDemo.gif';
import emailNewsletter from './assets/emailNewsletter.gif';



function App() {
  return (
    <div className="App max-w-full overflow-x-hidden">
      <Navbar></Navbar>
      
      {/* Hero Header*/}
      <div className='h-[50vh] mt-28'>
        <p className='text-5xl sm:text-7xl serif mx-auto max-w-[90%] md:max-w-[50%] text-center'>
        Introducing Daisy: the first AI grant writer
        </p>
        <p className='mt-6 text-xl text-gray-600 mx-auto max-w-[90%] md:max-w-[50%] text-center'>
        Win more grant funding for your nonprofit, by utilizing AI for grant research and writing
        </p>

        <button 
          className='bg-indigo-600 mt-12 text-xl text-white py-3 px-8 rounded-lg hover:bg-indigo-700 outline outline-2 outline-indigo-500'
          onClick={() => window.location.href = "https://grantmagicapp.com/signup"}
        >Get Started</button>
      </div>

    {/* Magic Features */}
    <div className='bg-gray-100 h-[75vh] sm:h-[100vh] flex flex-col items-center'>
      <p className='text-indigo-600 mt-20 text-md font-semibold'>DISCOVER GRANTS</p>
      <p className='serif text-5xl mt-6 px-4 text-center'>Find new grants without trying</p>
      <p className='text-xl mt-6 text-gray-600 max-w-[90%] md:max-w-[60%] text-center'>Daisy will scrape the internet for new grant opportunities, then send a weekly digest with personalized recommendations</p>
      <div className='w-full mt-10'>
      <img 
        src={emailNewsletter} 
        alt="Editor" 
        className="mx-auto w-[80%] sm:w-[60%]" 
        style={{ height: 'auto', borderRadius: '16px', boxShadow: '0 10px 14px rgba(0, 0, 0, 0.4)' }} 
      />
      </div>
    </div>

    {/* Magic Features */}
    <div className='h-[75vh] sm:h-[100vh]  flex flex-col items-center'>
      <p className='text-indigo-600 mt-20 text-md font-semibold'>FIND FUNDERS</p>
      <p className='serif text-5xl mt-6 px-4 '>Discover funders who love you</p>
      <p className='text-xl mt-6 text-gray-600 max-w-[90%] md:max-w-[60%] text-center'>Browse millions of actual grant awards, to find funders who are looking for nonprofits like yours</p>
      <div className='w-full mt-10'>
      <img 
        src={grantDatabaseDemo} 
        alt="Editor" 
        className="mx-auto w-[80%] sm:w-[60%]" 
        style={{ height: 'auto', borderRadius: '16px', boxShadow: '0 10px 14px rgba(0, 0, 0, 0.4)' }} />
      </div>
    </div>
    

    {/* Other Features */}
    <div className='bg-gray-100 h-auto flex flex-col items-center'>
      <p className='text-indigo-600 mt-20 text-md font-semibold'>PREMIUM FEATURES</p>
      <p className='serif text-5xl mt-6 px-4 text-center'>What else can Daisy do?</p>
      
      {/* Example Cards */}
      <div className='flex flex-col sm:flex-row justify-center mx-8 sm:mx-32 my-8 space-y-12 sm:space-y-0 sm:space-x-12'>
        <FeatureCard 
          image={rfpScoringDemo}
          title="Discover grant opportunities"
          subtitle="Daisy can score RFPs, to help discover grant opportunities you're eligible for"
        />

        <FeatureCard 
          image={projectManagementTools}
          title="Keep you organized"
          subtitle="Manage all your grants in one place. Daisy can send you alerts when you have grant deadlines approaching"
        />
      </div>
      <div className='flex flex-col sm:flex-row justify-center mx-8 sm:mx-32 my-8 space-y-12 sm:space-y-0 sm:space-x-12'>
        <FeatureCard 
          image={aiEditingDemo}
          title="Edit your work directly"
          subtitle="Daisy can edit your work directly, personalizing answers, or adjusting to meet word count requirements"
        />
        <FeatureCard 
          image={trainingDataDemo}
          title="Learn from your past work"
          subtitle="No extra effort needed. Simply upload past proposals, presentations, and reports for Daisy to study and remix for future proposals"
        />

      </div>      
    </div>

    {/* Pricing */}
    <div className='h-auto bg-indigo-900 flex flex-col items-center'>
      <p className='text-white mt-20 text-md font-semibold'>PRICING</p>
      <p className='serif text-5xl mt-6 px-4 text-center text-white'>How much does Daisy cost?</p>
      
      {/* Pricing Cards */}
      <div className='flex flex-col md:flex-row justify-center mx-4 md:mx-16 my-8 space-y-12 md:space-y-0 md:space-x-4'>
      
        {/* Free Plan */}
        <div className="card bg-white shadow-md rounded-lg py-8 px-6 sm:px-8 w-full sm:w-1/3 flex flex-col justify-between">
            <div className="p-4">
              <p className="text-gray-500 text-lg text-left ">Starter</p>
              <h2 className="text-5xl text-left serif mt-2">Free</h2> 
              <p className="text-gray-500 text-left mt-2 text-sm italic">Get started with GrantMagic</p>
              <hr className="my-3"></hr>
              <ul className="text-gray-500 text-left mt-2 space-y-4">
                <div className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Personalized grant newsletter (occasional)</span>
                </div>
                <div className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Find funders with grant award database</span>
                </div>                
              </ul>
            </div>
            
            <button className="w-full py-2 px-4 bg-indigo-500 text-white font-bold rounded hover:bg-indigo-700 transition duration-300 mt-6 mb-5" onClick={() => window.location.href = 'https://grantmagicapp.com/signup'}>
              Try Now
            </button>
        </div>
        
        {/* Premium Plan */}
        <div className="card bg-white shadow-md rounded-lg py-8 px-6 sm:px-8 w-full sm:w-1/3 flex flex-col justify-between">
          <div className="p-4">
            <p className="text-gray-500 text-lg text-left ">Premium</p>
              <h2 className="text-5xl text-left serif mt-2">$5/mo</h2>            
              <p className="text-gray-500 text-left mt-2 text-sm italic">Start winning more grant funding</p>
              <hr className="my-3"></hr>
              <ul className="text-gray-500 text-left mt-2 space-y-4">
                <li className="flex items-center">
                    <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                    <span>Personalized grant newsletter (weekly)</span>
                </li>
                <li className="flex items-center">
                    <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                    <span>Find funders with grant award database</span>
                </li>      
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Personalized database of active grant opportunities</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>AI-generated first drafts</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>AI grant editing (word counts, personalization)</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Grant project management tools</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Priority access to Support</span>
                </li>
              </ul>
            
          </div>
          <button className="w-full py-2 px-4 bg-indigo-500 text-white font-bold rounded hover:bg-indigo-700 transition duration-300 mt-6 mb-5" onClick={() => window.open('https://satodadj.gumroad.com/l/rlqzat', '_blank')}>
              Get Access
            </button>
        </div>

        {/* Enterprise Plan */}
        <div className="card bg-white shadow-md rounded-lg py-8 px-6 sm:px-8 w-full sm:w-1/3">
          <div className="p-4">
          <p className="text-gray-500 text-lg text-left ">Enterprise</p>
            <h2 className="text-5xl text-left serif mt-2">$30/mo</h2>            
            <p className="text-gray-500 text-left mt-2 text-sm italic">Create a leading grant program</p>
            <hr className="my-3"></hr>
            <ul className="text-gray-500 text-left mt-2 space-y-4">
                <li className="flex items-center">
                    <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                    <span>Personalized grant newsletter (weekly)</span>
                </li>
                <li className="flex items-center">
                    <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                    <span>Find funders with grant award database</span>
                </li>      
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Personalized database of active grant opportunities</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>AI-generated first drafts</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>AI grant editing (word counts, personalization)</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Grant project management tools</span>
                </li>
                <li className="flex items-center">
                  <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                  <span>Priority access to Support</span>
              </li>
              <li className="flex items-center">
                <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                <span>Collaborate with teammates</span>
              </li>
              <li className="flex items-center">
                <img src={check} alt="Checkmark" className="w-4 h-4 mr-2" />
                <span>Create multiple organizations / programs</span>
              </li>
            </ul>
            
          </div>
          <button className="w-full py-2 px-4 bg-indigo-500 text-white font-bold rounded hover:bg-indigo-700 transition duration-300 mt-6 mb-5" onClick={() => window.open('https://tally.so/r/mOzR6K', '_blank')}>
              Contact Us
            </button>
        </div>
      </div>
    </div>

    {/* FAQ Section */}
    <div id="faq" className='py-20 bg-gray-100'>
      <FAQ />
    </div>

    {/* Footer */}
    <footer className='bg-gray-100 text-gray-400 py-6 text-center shadow-lg'>
      <p>&copy; 2023 GrantMagic. All rights reserved.</p>
    </footer>
    </div>
  );
}

export default App;