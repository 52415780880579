import React from 'react';

const FeatureCard = ({ image, title, subtitle }) => {
  return (
    <div className="card bg-white shadow-md rounded-lg py-8 px-6 sm:px-8 max-w-lg">
      <div className="w-full h-48">
        {image && (
          <img src={image} alt={title} className="w-full h-full object-cover rounded-t-lg drop-shadow-md" />
        )}
      </div>
      <div className="p-4">
        <h2 className="text-3xl text-left serif">{title}</h2>
        <p className="text-gray-500 text-lg text-left mt-2">{subtitle}</p>
      </div>
    </div>
  );
};

export default FeatureCard;