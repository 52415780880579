import React, { useState } from 'react';
import './FAQ.css'; // Import the CSS file
import downArrow from './assets/downArrow.png';

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  const faqs = [
    {
      question: "What is GrantMagic?",
      answer: "GrantMagic is the company developing Daisy, the world's first AI grant writer. Our mission is to give nonprofits more time and money to serve their communities."
    },
    {
      question: "What is Daisy?",
      answer: "Daisy is an AI grant writer that helps nonprofits automate grant research and writing."
    },
    {
      question: "How does Daisy work?",
      answer: "When you signup with GrantMagic, you'll answer a few questions about your organization. From there, Daisy will start searching for grants on your behalf."
    },
    {
      question: "Who is this made for?",
      answer: "Nonprofits in the United States that could benefit from grant funding, but don't have enough spare resources for grant research and grant writing."
    },
    {
      question: "How much does it cost?",
      answer: "Starting at $5 per month, GrantMagic is more than 10x cheaper than alternative grant databases."
    },
    {
      question: "How can I sign up?",
      answer: <a href="https://grantmagicapp.com/signup" className="text-indigo-600 hover:text-indigo-700">Sign up for free access here.</a>
    }
  ];

  return (
    <div className="max-w-2xl mx-auto p-4">
      <h2 className="text-5xl serif text-center mb-16">Frequently asked questions</h2>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div key={index} className="border-b border-gray-200">
            <button
              className="w-full text-left py-2 text-lg font-medium text-gray-700 focus:outline-none flex justify-between items-center"
              onClick={() => toggleFAQ(index)}
            >
              {faq.question}
              <img src={downArrow} className={`w-4 h-4 transform transition-transform duration-300 ${openIndex === index ? '-rotate-180' : ''}`} alt="Toggle" />
            </button>
            <div className={`faq-answer ${openIndex === index ? 'faq-answer-enter' : ''} py-2 text-gray-600 text-left`}>
              {faq.answer}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
